function Promotion() {
  return (
    <div>
      <p>
        Pobail is a service that lets you build communities. It's currently under construction but read on to find out
        the exciting stuff we're working on.
      </p>
      <h2>Build Your Community</h2>
      <p>
        Create your own corner of the internet and invite your friends to join. Share cool stuff from around the web and
        have meaningful conversations with people who like the stuff you do. Find open communities for the stuff you
        like and make connections through them.
      </p>
      <h2>Protect Your Space</h2>
      <p>
        Choose whether to be a wholly open community, an open community with closed topics, or keep it totally private.
        Set your own community rules and delegate moderators to make sure they're followed rather than relying on
        unsatisfactory algorithms.
      </p>
      <h2>Go FreeForum</h2>
      <p>
        Create threads free from the constraints of topics, write a TLDR and then whatever you want behind it then
        assign some tags for topic, don't be limited to just one! Create suggested topics for your community and set
        sensitive ones to private. See what's happening across a community at a glance or delve into a specific topic.
        Get the latest updates, not what the algorithm wants you to see.
      </p>
    </div>
  );
}

export default Promotion;