import './App.css';
import Promotion from "./Pages/Promotion";

function App() {
  return (
    <div>
      <header>
        <div className="box">
          <h1><a className="button" href="/">HOME</a> Pobail - The Community Building Service</h1>
        </div>
      </header>
      <Promotion />
    </div>
  );
}

export default App;
